import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "i18n";
import {
  addFeedToNewsletter,
  initTemplatesList,
  setCurrentNewsletterTemplate,
  setNewsletterBlocks,
} from "../../actions/actions/theme";
import { fetchTemplate } from "../../actions/thunks/theme";
import {fetchUser} from "../../actions/thunks/user";
import { CSSTransition } from "react-transition-group";
import { ucFirst } from "../../services/common";
import { initArticles } from "../../actions/actions/article";
import { decomposeNewsletter } from "../../services/block/newsletter";
import Loader from "react-loaders";
import { replaceEmailContent ,getUserNameForAvatar} from "../../services/utils";
import {
  initCurrentEmailCampaignData,
  setCurrentEmailCampaignData,
} from "../../actions/actions/currentCampaign";
import { store } from "../../index.js";
import {
  getLocaleLanguage,
} from "EmailingAPI";
const mapStateToProps = (state) => ({
  templates: state.themes.list.items,
  currentTemplate: state.themes.newsletter.currentTemplate,
  blocks: state.themes.newsletter.blocks,
  html: state.currentCampaign.email.html,
  currentClient: state.auth.currentClient,
  object: state.currentCampaign.email.object,
  textContent: state.currentCampaign.email.textContent,
  profession: state.currentCampaign.email.profession,
  fromName: state.currentCampaign.email.fromName,
  avatar : state.currentCampaign.email.avatar,
  mutatedContent: state.currentCampaign.email.mutatedContent,
  secondName: state.currentCampaign.email.secondName,
  secondProfession: state.currentCampaign.email.secondProfession,
  secondAvatar: state.currentCampaign.email.secondAvatar,
  signature: state.currentCampaign.email.signature,
  imgPreview: state.currentCampaign.email.imgPreview,

});

const mapDispatchToProps = (dispatch) => ({
  fetchTemplate: (id) => dispatch(fetchTemplate(id)),
  initTemplates: () => dispatch(initTemplatesList()),
  setCurrentNewsletterTemplate: (data) =>
    dispatch(setCurrentNewsletterTemplate(data)),
  setNewsletterBlocks: (data) => dispatch(setNewsletterBlocks(data)),
  initArticles: () => dispatch(initArticles()),
  addFeedToNewsletter: (article) => dispatch(addFeedToNewsletter(article)),
  initCurrentCampaign: (data) => dispatch(initCurrentEmailCampaignData(data)),
  setCurrentCampaign: (data) => dispatch(setCurrentEmailCampaignData(data)),
  fetchUser: (id) => dispatch(fetchUser(id)),
  
});

@connect(mapStateToProps, mapDispatchToProps)
export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: DESKTOP,
      currentObject: this.props.object,
      template: null,
      avatarToUse: null,
      professionToUse: null,
    };
    this.handleDeviceChange = (device) => this.setState({ device });
  }

  componentDidMount() {
    this.updateNewsletter();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isPeriodChanged, currentPage,secondAvatar , signature,secondProfession,setCurrentCampaign} = this.props;
    if (
      (prevProps.isPeriodChanged !== isPeriodChanged && isPeriodChanged) ||
      (prevProps.currentPage !== currentPage &&
        currentPage === "RICH_TEXT_PAGE")
    ) {
      this.updateNewsletter();
    }
   
  }

  updateNewsletter = () => {
    const {
      setCurrentNewsletterTemplate,
      setNewsletterBlocks,
      initArticles,
      blocks,
      newsletterId,
      currentClient,
      currentPage,
      setCurrentCampaign,
    } = this.props;
    let plan = "";
    let isLogoUaVisible = true;
    let lng = getLocaleLanguage();

    if (
      currentClient &&
      currentClient["newsletterPreferences"] &&
      currentClient["newsletterPreferences"]["apps"]?.length > 0
    ) {
      let filteredApps = currentClient["newsletterPreferences"]["apps"].filter(
        (app) => app.type === "BLOG_NL_CREATE",
      );
      plan = filteredApps[0].config;
      isLogoUaVisible = filteredApps[0]?.isLogoUaVisible ?? true;
    }

    if (newsletterId == null || plan === "PERSONALIZED") {
      this.props.initTemplates();
      let idTheme = null;

      if (currentPage === "SEND_PAGE") {
        if (currentClient.id === 4) {
          // Mapping des langues et des ID de thème spécifiques pour Deg template (8 articles)
          const themesClient4 = {
              en: 13014,  
              nl: 13017, 
              fr: 11224 
          };
      
          // Sélection de l'ID de thème en fonction de la langue
          idTheme = themesClient4[lng] || themesClient4.fr;
      
      } else {
          // Mapping des langues et des ID de thème pour chaque plan et visibilité du logo
          const themes = {
              LITE: {
                  en: 13023,  
                  nl: 13024,  
                  fr: 10644  
              },
              STANDARD: {
                  en: 13025, 
                  nl: 13026, 
                  fr: 11693  
              },
              logoUaVisible: {
                  en: 13027, 
                  nl: 13028, 
                  fr: 10267  
              },
              noLogoUa: {
                  en: 13029,  
                  nl: 13030, 
                  fr: 11762 
              }
          };
      
          // On vérifie le plan et la visibilité du logo pour sélectionner l'ID du thème
          if (plan === "LITE") {
              idTheme = themes.LITE[lng] || themes.LITE.fr; // Lite template (2 articles)
          } else if (plan === "STANDARD") {
              idTheme = themes.STANDARD[lng] || themes.STANDARD.fr; // Standard template (4 articles)
          } else {
              if (isLogoUaVisible) {
                  idTheme = themes.logoUaVisible[lng] || themes.logoUaVisible.fr; // Personalized template (8 articles) with Ua logo
              } else {
                  idTheme = themes.noLogoUa[lng] || themes.noLogoUa.fr; // Personalized template (8 articles) without Ua logo
              }
          }
      }
      
      } else if (currentPage === "RICH_TEXT_PAGE") {
        if (currentClient.id === 4) {
          // Mapping des langues et des ID de thème spécifiques pour Deg
          const themesClient4 = {
              en: 13012,  
              nl: 13013, 
              fr: 12881 
          };
      
          // Sélection de l'ID de thème en fonction de la langue
          idTheme = themesClient4[lng] || themesClient4.fr; // Utilise FR par défaut si la langue n'est pas trouvée
      } 
       else {
          // Mapping des langues et des ID de thème pour chaque combinaison de plan et visibilité du logo
          const themes = {
              en: {
                  withoutLogoUa: 12986,
                  liteStandardOrLogo: 12985
              },
              nl: {
                  withoutLogoUa: 12989,
                  liteStandardOrLogo: 12987
              },
              fr: {
                  withoutLogoUa: 12585,
                  liteStandardOrLogo: 12176
              }
          };
  
          // On vérifie la langue, le plan et la visibilité du logo pour sélectionner l'ID du thème
          if (plan === "LITE" || plan === "STANDARD" || isLogoUaVisible) {
              idTheme = themes[lng]?.liteStandardOrLogo || themes.fr.liteStandardOrLogo;
          } else {
              idTheme = themes[lng]?.withoutLogoUa || themes.fr.withoutLogoUa;
          }
      }
      setCurrentCampaign({ theme: { id: idTheme } });
  }
      if (idTheme) {
        this.props.fetchTemplate(idTheme).then((res) => {
          const fetchedTemplate = res.value.data.data;
          this.setState({ template: fetchedTemplate });
          setCurrentNewsletterTemplate(fetchedTemplate);

          let feeds = blocks.reduce((acc, { feeds }) => {
            return [...acc, ...feeds];
          }, []);

          decomposeNewsletter(fetchedTemplate, (blocks) => {
            let i = 0;
            blocks =
              feeds && feeds.length > 0
                ? blocks.map((block) => {
                    if (
                      block.type !== "UNREFERENCED_BLOCK" &&
                      feeds.length > i
                    ) {
                      block.feeds = feeds.slice(i, (i += block.column));
                      return block;
                    }
                    return block;
                  })
                : blocks;
            setNewsletterBlocks(blocks);
          });
        });
        initArticles();
      }
    }
  };

  close = () => {
    const sidebar = document.getElementById("settings__sidebar");
    if (sidebar) {
      sidebar.classList.remove("display");
      const mask = document.getElementsByClassName("mask").item(0);
      mask.classList.add("hide");
    }
  };

  getTemplateHtml() {
 
    const { object, html, textContent, setCurrentCampaign, fromName, profession, avatar, secondName, secondProfession,secondAvatar,signature,currentClient,imgPreview} = this.props;
    const { template ,avatarToUse,professionToUse} = this.state;
    const fiduciaryLogo = store.getState().auth.currentClient.avatarUrl;
    let content = html;
    if (template && template.content) {
      const imageUrl1 = (currentClient.id === 4)
      ? (avatar !== undefined
          ? `<img src="${avatar}" alt="Photo de profil" style="height: 100%;">`
          : `<div class="image-placeholder" style="width: 100%; height: 100%; text-align: center; align-content: center; font-weight: bold; font-size: 40px; color: white;">${getUserNameForAvatar(fromName)}</div>`)
      : (avatar !== undefined
          ? `<img src="${avatar}" alt="Photo de profil" style="width: 50px; height: 50px; border-radius: 10px;">`
          : `<div class="image-placeholder" style="width: 50px; height: 50px; border-radius: 10px; border: 2px solid var(--Gris-4, #F1F2F4); background: lightgray 50% / cover no-repeat; text-align: center; align-content: center; font-weight: bold; font-size: 24px; color: white;">${getUserNameForAvatar(fromName)}</div>`);
  
        content = replaceEmailContent(
            {
                body: textContent,
                subject: object,
                fullName: fromName,
                profession: profession,
                imageUrl: imageUrl1,
                fiduciaryLogoUrl: fiduciaryLogo,
                fullName2: secondName,
                profession2: secondProfession,
                avatar2: secondAvatar,
                currentClient: currentClient,
                imagePreview: imgPreview,
            },
            template.content,
        );

        setCurrentCampaign({ mutatedContent: content });
        setCurrentCampaign({ html: textContent });
    }

    return content;
}


  render() {
    const { device } = this.state;
    const { html, showPreview, currentPage } = this.props;
    let iframeProps = {};
    iframeProps.srcDoc = html;
    return (
      <div id="settings__sidebar" className="preview-sidebar">
        <div
          className="columns small-5 preview-sidebar__close"
          onClick={this.close}
        >
          <span className="preview-sidebar__close-title">
            {_("fermer l'aperçu")}
          </span>
          <span className="preview-sidebar__close-icon icomoon icon-tt-close" />
        </div>

        {!showPreview && (
          <div className="drag-drop-editor__overlay preview-sidebar__loader">
            <Loader type="line-spin-fade-loader" />
          </div>
        )}

        <CSSTransition
          in={showPreview}
          timeout={500}
          classNames="campaign-preview"
          unmountOnExit
        >
          <div className={`preview-sidebar__content medium-11 m-l-s`}>
            <div className="campaign-preview__device">
              {[DESKTOP, MOBILE].map((value) => (
                <span
                  key={value}
                  className={`${value === device ? "active" : ""}`}
                  onClick={() => this.handleDeviceChange(value)}
                >
                  {ucFirst(_(value.toLowerCase()))}
                </span>
              ))}
            </div>
            <div className={`campaign-preview__content`}>
              <iframe
                className={`${MOBILE === device ? "mobile" : ""}`}
                ref="iframe"
                scrolling="auto"
                allowFullScreen=""
                {...iframeProps}
                srcDoc={
                  currentPage === "RICH_TEXT_PAGE"
                    ? this.getTemplateHtml()
                    : html
                }
              />
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

const DESKTOP = "DESKTOP";
const MOBILE = "MOBILE";
