export default {
  home: "Emailing",
  "Send your emailings": "Envoyez vos emailings",
  "to the look & feel of your community": "au look & feel de votre communauté",
  "with little effort!": "avec peu d'effort!",
  "Create and send": "Créer et envoyer",
  signIn: "Connexion / Inscription",
  "About Tamtam": "À propos de Tamtam",
  owrApps: "Nos applications",
  pageNotFound: "Cette page n'a pas pu être trouvée!",
  goBackToHome: "Retournez à la page d'accueil",
  logout: "Se déconnecter",
  help: "Aide",
  Communities: "Communautés",
  Dashboard: "Tableau de bord",
  campaigns: "Campagnes",
  webPage: "Page web",
  previous: "Précédent",
  next: "Suivant",
  filter: "Filtre",
  filters: "Filtres",
  "invitation type": "Type d'invitation",
  "roles status": "statut des rôles",
  status: "État",
  DELETED: "CORBEILLE",
  SENT: "ENVOYÉE",
  ERROR: "ERREUR",
  RECEIVED: "REÇUS",
  OPEN: "OUVERT",
  UNREAD: "NON LU",
  "send an email": "ENVOYER UN EMAIL",
  RECIPIENT_LIST_CREATED: "En cours",
  CREATED: "CRÉÉ",
  language: "Langue",
  reset: "Réinitialiser",
  searchBySubject: "Recherche par sujet",
  cancel: "annuler",
  save: "ENREGISTRER",
  codeOwn: "Rédigez la vôtre",
  createdAt: "Créée le",
  groups: "Groupes",
  sent: "Envoi",
  enterText: "Message ...",
  enterName: "Entrez un nom",
  deleteCampaignConfirm: "Voulez-vous vraiment supprimer la campagne?",
  deleteManualRecipientsConfirm:
    "Voulez-vous vraiment supprimer tous les emails?",
  deleteAll: "Tout supprimer X",
  delete: "supprimer",
  fromEmail: "de l'email",
  fromName: "du nom",
  replyTo: "répondre à",
  recipientType: "Type de destinataire",
  contactType: "Type de contact",
  typeTestEmail:
    "Tapez les adresses mail auxquelles vous souhaitez envoyer le test",
  settings: "paramètres",
  warning: "Alerte",
  groupsIncluded: "Groupes inclus",
  excludedGroups: "Groupes exclus",
  selectGroups: "Sélectionnez des groupes",
  excludeGroups: "Exclure des groupes",
  "Select event": "Sélectionnez un événement",
  "Select invitation type": "Sélectionnez un type d'invitation",
  "Select visitor type": "Sélectionnez un type de visiteur",
  "Select slots": "Sélectionnez des slots",
  "Select activities": "Sélectionnez les activités",
  "Exclude activities": "Exclure les activités",
  "Select status": "Sélectionnez les status",
  "Exclude status": "Exclure les status",
  "Select options": "Sélectionnez les options",
  "Select formulas": "Sélectionnez des formules",
  sendTitle: "Vous êtes sur le point d'envoyer une campagne à",
  tested: "Testé",
  attachments: "Pièces jointes",
  confirm: "CONFIRMER",
  confirmSendCampaign: "Êtes-vous sûr d'envoyer cette campagne?",
  noNotNow: "NON, PAS MAINTENANT",
  yesSend: "OUI, ENVOYER",
  sendTestTo: "Envoyer un SMS de test à",
  sendEmailTestTo: "Envoyer un email de test à",
  sendTest: "ENVOYER LE TEST",
  send: "envoyer",
  PLAIN_TEXT: "TEXT",
  WEB_PAGE: "PAGE WEB",
  DND_TEMPLATE: "MODÈLE",
  selectTarget: "Sélectionnez un public cible",
  campaignInfo: "Informations sur la campagne",
  invalidObject:
    "l'objet doit comporter au moins 5 caractères et pas plus de 200 caractères",
  invalidFromName: "du nom invalide, veuillez entrer plus de 4 caractères",
  invalidEmail: "email adresse invalide",
  "invalid link": "Lien invalide",
  emailAdd: "Ajoutez des emails et tappez ENTER",
  userAdd: "Ajoutez un utilisateur et tappez ENTER",
  deleteCampaignSuccess: "Suppression reussie",
  campaignDeleted: "La campagne a était supprimée avec succès",
  campaignTestSent: "Un email de test a été envoyé avec succès",
  campaignTestSuccess: "Test réussi",
  campaignSent: "La campagne a été envoyée avec succès",
  campaignSentSuccess: "Envoi reussi",
  campaignAdded: "La campagne a était enregistrée avec succès",
  addCampaignSuccess: "Ajout réussi",
  successfulAction: "Action réussie",
  operationCompleted: "Action réalisée avec succès",
  errorOccured: "Une erreur est survenue, essayez plus tard.",
  "Sorry there was a problem processing your request":
    "Désolé, un problème est survenu lors du traitement de votre demande ",
  incompleteOperation:
    "Impossible de terminer l'opération, des données incorrectes ont été soumises",
  invalidUrl:
    "Impossible de terminer l'opération, un url invalide a été soumis",
  error: "erreur",
  enterUrl: "Entrer l'URL",
  search: "Recherche",
  deliveredTo: "Livré à",
  emailsOpen: "Emails ouverts",
  linkClicks: "Liens cliqués",
  Bounce: "Rebond",
  open: "ouverture",
  complaint: "plainte",
  "bounce details": "détails de rebond",
  permanent_bounce: "nouveau rebond permanent",
  old_permanent_bounce: "ancien rebond permanent",
  transient_bounce: "rebond temporaire",
  undetermined_bounce: "rebond indéterminé",
  bounced: "rebondi",
  "bounce type": "type de rebond",
  bounce_info:
    "REBOND décrit un email non délivré au destinataire en raison : d'un problème permanent (ex: nom de domaine n’existant plus ou mauvaise syntaxe de l’email), ou d'un problème temporaire (ex: le serveur du destinataire est indisponible ou sa boîte de réception est pleine).",
  Spam: "",
  recipientsMessage: "ce filtre prend en considération la langue :",
  pauseCampaign:
    "pause de la campagne empêche l'envoi <br>d'autres email jusqu'à ce qu'elle soit réactivée",
  pauseSending: "pause l'envoi d'autre email",
  stopCampaign: "arrêter définitivement l'envoi de la campagne",
  resume: "reprendre",
  register_from_email:
    "Cette adresse n'est pas valide pour l'envoi via Amazon, cliquez sur SOUMETTRE pour lancer le processus d'enregistrement",
  reply_aws_email:
    "Un email a été envoyé par Amazon à l'adresse email Répondez-y et cliquez sur RAFRAICHIR",
  replyToError:
    "Veuillez entrer un email valide ou bien un text dans le format : nom <email@example.com>",
  validate: "Valider",
  refresh: "rafraîchir",
  //" To : ": " À : " ,
  GUEST: "INVITÉ",
  replayable: "Comprend l'option: Replay",
  free: "Gratuit",
  Speakers: "Orateurs",
  SPEAKER: "ORATEUR",
  CONTACT: "",
  PROSPECT: "",
  Download: "Télecharger",
  DownloadCSV: "Télecharger le CSV",
  notOpenYet: "Pas encore ouvert",
  notClickedYet: "Pas encore cliqué",
  firstName: "Prénom",
  lastName: "Nom",
  userGroup: "Groupe d'utilisateur",
  openedAt: "Ouvert à",
  clickedAt: "Cliqué à",
  live: "En Live",
  replay: "En Replay",
  this_week: "Cette Semaine",
  Training_type: "Type de formation",
  PARTNER: "PARTENAIRE",
  MAILING_LIST: "liste de diffusion",
  SURVEY_TARGETS: "cibles d'enquête",
  MANUAL: "MANUEL",
  templateChoices: "Veuillez choisir une template",
  "first name": "prénom",
  "last name": "nom",
  stopCampaignConfirm:
    "Voulez-vous vraiment arrêter définitivement cette campagne?",
  stop: "ARRÊTER",
  event: "evénement",
  "Included activities": "Activités incluses",
  "Excluded activities": "Activités exclues",
  "Included status": "Status incluses",
  "Excluded status": "Status exclues",
  Formulas: "Formules",
  Slots: "Slots",
  Options: "",
  all: "tous",
  NEXT: "SUIVANT",
  emailCampaigns: "Campagnes email",
  "Are you sure you want to delete this email ?":
    "Êtes-vous sûr de vouloir supprimer cet e-mail?",
  smsCampaigns: "Campagnes SMS",
  "Enter your message": "Entrez votre message",
  fromPhoneNumber: "DU NUMÉRO DE TÉLÉPHONE ",
  smsMessage: "Message SMS",
  searchByCampaignName: "Recherche par nom de campagne",
  testRecipientsNameAdd: "Ajoutez des noms de déstinataires et tappez ENTER",
  typeTestRecipients:
    "Séléctionner les destinaires auquelles vous souhaitez envoyer le test",
  campaignSMSTestSent: "Un SMS de test a été envoyé avec succès",
  nothing_found: "Oups, Aucun résultat ne correspond à votre recherche",
  empty_sms_campaigns_list:
    "Votre organisation n'a aucune campaigne SMS pour le moment",
  empty_sms_bills_list:
    "Votre organisation n'a aucune facture SMS pour le moment",
  empty_sms_credit_purchase_list:
    "Aucune demande d'achat de crédit SMS pour le moment",
  empty_recipients_list: "Aucun destinataire à afficher pour le moment",
  yes: "oui",
  no: "non",
  french: "français",
  english: "anglais",
  dutch: "néerlandais",
  overview: "aperçu",
  view: "voir",
  "The campaign report": "Rapport de la campagne",
  "links tracking": "suivi des liens",
  recipients: "destinataires",
  recipient: "destinataire",
  reviews: "avis",
  unapproved: "non approuvé",
  approved: "approuvé",
  approvals: "approbations",
  approval_preferences: "Les préférences d'approbation d'une campagne emailing",
  "send me a test at": "m'envoyer un test à",
  "send to approval teams": "envoyer aux approbateurs",
  "Send your campaign immediately": "Envoyez votre campagne immédiatement",
  "Schedule updating of your campaign for a specific time":
    "Planifiez la mise à jour de votre campagne à une heure précise",
  "Add a comment": "Ajouter un commentaire",
  "Schedule sending of your campaign for a specific time":
    "Programmer l'envoi de votre campagne pour une heure précise",
  "the approvers": "les approbateurs",
  "approval is required": "l'approbation est obligatoire",
  "automatic sending": "l'envoi est automatique",
  "the approval threshold": "Seuil d'approbation",
  automatic_sending_paragraph:
    "envoyer la campagne automatiquement lorsque le seuil d'approbation est atteint sans rejet",
  approval_obligatory_paragraph:
    "la campagne email ne peut être pas envoyée sans approbation",
  minimum_acceptance_paragraph:
    "le nombre minimum d'acceptation dans l'équipe requis pour approuver la campagne",
  no_member_found:
    "Ajoutez des membres à votre équipe, les membres examineront votre campagne avant de l'envoyer.",
  "Configure your community's test  preferences":
    "Configurez les préférences de test de votre communauté",
  "Oops no approval team found":
    "Oups aucune équipe d'approbation n'a été trouvée",
  teams: "équipes",
  "Add team": "Ajouter une équipe",
  "Are you sure you want to delete this team ?":
    "Êtes-vous sûr de vouloir supprimer cette équipe?",
  members: "membres",
  "Edit team": "Editer l'équipe",
  "Create team": "Créer une équipe",
  "Add a team description": "Ajouter une description de l'équipe",
  "Add a member": "Ajoutez un membre",
  "delivery date": "date et heure d'envoi",
  "Drag and drop some files here, or click to select files to upload.":
    "Faites glisser et déposer des fichiers ici, ou cliquez pour sélectionner les fichiers à télécharger.",
  Attachments: "Pièces jointes",
  attachment: "pièce jointe",
  reason: "raison",
  "Thank you for approving this campaign":
    "Merci d'avoir approuvé cette campagne",
  "Your view is taken into consideration": "Votre avis est pris en compte",
  "You are about to reject the campaign, please specify the reason":
    "Vous êtes sur le point de rejeter la campagne, veuillez préciser la raison",
  "You are about to approve the campaign":
    "Vous êtes sur le point d'approuver la campagne",
  approve: "approuver",
  about_us_content: `est une suite d'outils qui permet à une communauté de communiquer, partager, organiser ou  collaborer efficacement.  La plateforme est équipée de diverses applications assemblées selon les besoins par le client.  Grace  à nos outils mutualisés, nos clients disposent des dernières technologies sans devoir en assurer le développement au profit de leur core business.`,
  createEmail: "créer une campagne email",
  createSms: "créer une campagne sms",
  createCampaign: "Créer une campagne",
  smsSendTitle: "Vous êtes sur le point d'envoyer une campagne SMS à",
  campaignName: "Nom de la campagne",
  smsOpen: "SMS ouverts",
  unsent: "Non envoyé",
  originator: "Expéditeur",
  remainingChars: "Caractères restants",
  chars: "Caractères",
  pauseSendingSMS: "suspendre l'envoi d'autres messages SMS",
  pauseSMSCampaign:
    "pause de la campagne empêche l'envoi <br>d'autres SMS jusqu'à ce qu'elle soit réactivée",
  smsTotalCount: "Nombre total de SMS",
  poorCredit: "  Votre crédit est insuffisant pour envoyer la campagne !",
  richCredit: "  Votre crédit est suffisant, vous avez encore:",
  "You can send the campaign.": "Vous pouvez envoyer la campagne.",
  " You only have: ": "  Vous n'avez que:",
  "No approval request is sent for this release":
    "Aucune demande d'approbation n'est envoyé pour cette version",
  "Sent to": "Envoyé à",
  "Delivered to": "Livré à",
  "approvers feedback for the test version":
    "les retours des approbateurs pour la version de test",
  preview: "aperçu",
  upload: "télécharger",
  apply: "appliquer",
  Close: "Fermer",
  "Once you close your changes will be lost. Do you really want to continue?":
    "Vos modifications seront perdues. Voulez-vous vraiment continuer?",
  "INSERT FIELDS": "INSÉRER LES CHAMPS",
  "search by title": "recherche par titre",
  none: "aucun",
  "default model": "modèle par défaut",
  "last modified on": "dernière modification le",
  by: "par",
  share: "partager",
  replicate: "reproduire",
  original: "original",
  replication: "réplication",
  LEGAL_REPRESENTATIVE: "Représentant légal",
  OFFICIAL: "Officiel",
  EXTERNAL: "Externe",
  MANAGER: "Manager",
  "Apply authorization to selected users":
    "Appliquer l'autorisation aux utilisateurs sélectionnés",
  "New user": "Nouveau utilisateur",
  "Select All": "Tout sélectionner",
  "Deselect All": "Tout déselectionner",
  "allow deletion": "permettre la suppression",
  "allow change": "permettre le changement",
  "In progress": "En cours",
  Buffered: "Tamponné",
  "allow create campaign": "Autoriser la création de la campagne",
  "allow send campaign": "Autoriser l'envoi de la campagne",
  "Are you sure you want to delete this model":
    "Êtes-vous sûr de vouloir supprimer ce modèle",
  "Are you sure you want to hide this model":
    "Êtes-vous sûr de vouloir masquer ce modèle",
  "sms finances": "finances sms",
  "SMS Bills": "Factures SMS",
  "Buy credit": "Acheter du crédit",
  " Request": " Commander",
  buyCreditDesc:
    "Veuillez saisir (en €) le nombre de crédits avec lequel vous voulez recharger votre solde SMS dans le cadre suivant.",
  buyCreditInfo:
    "Vos crédits vous permettent d'envoyer des messages vers n'importe quel pays.",
  requestSent: "Votre commande a été envoyée avec succès",
  sendRequestSuccess: "Envoi de commande réussi",
  PENDING: "EN ATTENTE",
  PUBLISHED: "PUBLIÉ",
  VALIDATED: "VALIDÉ",
  REJECTED: "REJETÉ",
  "No, allow for all users": "Non, autoriser pour tous les utilisateurs",
  "Yes, allow for certain users": "Oui, autoriser pour certains utilisateurs",
  "Restrict the use of this template": "Restreindre l'utilisation de ce modèle",
  "Select approval teams": "Sélectionner les équipes d'approbation",
  "user preferences": "les préférences utilisateurs",
  "Add an image for this template": "Ajouter une image pour ce modèle",
  "Phone numbers with check mark":
    'Les n° de téléphones ayant un signe "coche" ',
  "have been verified by our system": "ont été vérifiés par notre système",
  "Add an image to the library": "Ajouter une image à la bibliothèque",
  privilege: "privilège",
  resend: "renvoyer",
  "resend the email to": "renvoyer l'email à",
  lists: "listes",
  "Create list": "Créer une liste",
  "Edit list": "Modifier la liste",
  "is sms list": "est une liste sms",
  "search by name": "rechercher par nom",
  subscribers: "abonnés",
  "mandatory list": "liste obligatoire",
  "no description": "aucune description",
  name: "nom",
  Credit: "Crédit",
  Cost: "Coût",
  remove_from_list: "Êtes-vous sûr de supprimer le contact de cette liste",
  "manage subscribers": "gérer les abonnés",
  "add list": "Ajouter une liste",
  "mailing lists subscriptions": "abonnements aux listes de diffusion",
  "selected items": "les éléments sélectionnés",
  buffered:
    "Message encore sur le réseau et en attente de livraison au destinataire.<br >Un SMS tamponné est généralement dû à une congestion du réseau<br >ou de l'appareil du destinataire.",
  detailed_bill: "Facture détaillée campagne SMS",
  "exclude event registrants": "exclure les inscrits à l'événement",
  subscribe: "s'inscrire",
  options: "",
  "embedded form": "formulaire incorporée",
  "copy/paste onto your site": "copier/coller dans votre site",
  copy: "copier",
  "copied !": "copié !",
  campaign_name: "Nom de la campagne",
  "TOTAL APPROXIMATE COST": "COÛT TOTAL APPROXIMATIF",
  "invalid price": "prix invalide",
  total_delivered_recipients: "Total destinataires ciblés",
  total_delivered_sms: "Total SMS envoyés",
  amount_due: "Montant total",
  country: "Pays",
  unit_price: "Prix unitaire",
  delivered_by_country: "Destinataires ciblés",
  sms_delivered_by_country: "SMS envoyés",
  total_amount_by_country: "Total",
  sms_length_warning:
    "Caractères spéciaux<br>Vous avez utilisé des caractères spéciaux (unicode) qui ne peuvent pas être affichés dans un message SMS standard.<br>Choisissez une option ci-dessous :Envoyer un SMS ne contenant que des caractères standards.<br>Les caractères spéciaux seront remplacés par leurs équivalents standards, ou le cas échéant, supprimés.Afficher les caractères spéciaux dans mon message SMS.<br>Attention ! Dans ce cas, le SMS ne peut contenir que 70 caractères.<br>Le compteur de caractères s'adapte en fonction",
  approximateCostInfo:
    "Le coût total est approximatif suite à l'insertion de champs dynamiques",
  "TOTAL COST": "COÛT TOTAL",
  sms_warning_lead: "Caractères spéciaux",
  sms_warning_length_disclaimer:
    "Attention‼ la longueur de votre SMS dépend des caractères utilisés.",
  sms_warning_explanation:
    "Actuellement, vous utilisez  des caractères spéciaux qui ne peuvent pas être affichés dans un message SMS standard sans en affecter la taille.",
  sms_waring_more_precisely: "Plus précisément",
  sms_warning_gsm_7bit_guideline:
    "les caractères suivants sont comptés pour deux caractères dans un SMS, au lieu de un seul caractère:",
  sms_warning_utf16_guideline:
    "Les caractères spéciaux (ex. : á ç é ê í ó ú à è ë ï ö ü ĳ …) réduisent la capacité du SMS à seulement 70 caractères au lieu de 160.",
  sms_warning_advice:
    "Si vous ne souhaitez pas subir les inconvénients de cet usage particulier, nous vous invitons à n’utiliser que des caractères standards.",
  sms_warning_gsm_7bit_ext_list: "⏎  \\  ^  ~  [  ] {  } |  €",
  sms_warning_counter_disclaimer:
    "Le compteur de caractères s'adaptera en conséquence.",
  validationCreditRequestConfirm:
    "Voulez-vous vraiment valider la demande de crédit?",
  creditRequestValidated: "La requête de crédit a été validée avec succès",
  validateCreditSuccess: "Validation reussie",
  date: "",
  "client name": "nom du client",
  Reject: "Rejeter",
  rejectionCreditRequestConfirm:
    "Voulez-vous vraiment rejeter la demande de crédit?",
  creditRequestRejected: "La requête de crédit a était rejetée avec succès",
  rejectCreditSuccess: "Rejet réussi",
  updatedAt: "Modifié le",
  authorized_balance: "Crédit autorisé",
  current_balance: "Solde financier",
  "include form title": "inclure le titre du formulaire",
  "show only required fields": "afficher uniquement les champs obligatoires",
  "show required field indicators": "afficher les indicateurs de champ requis",
  "show language choices": "afficher les choix de langue",
  required: "requis",
  "subscriber type": "type d'abonné",
  "Nothing to show": "Aucunes notification n'est diponible",
  "add new subscribers": "ajouter de nouveaux abonnés",
  "remove subscribers": "supprimer des abonnés",
  unsubscribe: "Se désabonner",
  unsubscribed: "désabonné",
  undelivered: "non livré",
  "do you want to add new subscribers to this list?":
    "Voulez-vous ajouter de nouveaux abonnés à cette liste?",
  "do you want to remove subscribers from this list?":
    "Voulez-vous supprimer des abonnés de cette liste?",
  "Processing...": "Traitement en cours ...",
  Processing: "Traitement en cours",
  select_formule: "Sélectionner la formule",
  "Select the mailing lists in which your prospects will be registered":
    "Sélectionnez les listes de diffusion dans lesquelles seront enregistrées vos prospects",
  "Select lists": "Sélectionner des listes",
  "Loading suggestions ...": "Chargement des suggestions ...",
  "search by label": "recherche par libellé",
  containDynamicFields: "Contient des champs dynamiques",
  CREDIT: "CRÉDIT",
  DEBIT: "DÉBIT",
  "sms credit validation": "validation de crédit sms",
  "send me a test email": "m'envoyez un email de test",
  "show mailing lists choice": "afficher le choix des listes de diffusion",
  "Please choose the lists to which you want to subscribe":
    "Veuillez choisir les listes auxquelles vous souhaitez vous abonner",
  back: "Retour",
  campaign_created_at: "Créée le",
  at: "à",

  "Emailing Chart": "",
  "filter by language": "filtrer par langue",
  "filter by status": "filtrer par état",
  "Nothing Found": "Aucun résultat",
  Balance: "Solde",
  no_approval_sent:
    "Aucune demande d'approbation n'est envoyée pour cette version",
  "Sent at": "Envoyée le",
  Language: "Lang.",
  "Phone number": "Numéro de téléphone",
  "Copy/paste your target's emails":
    "Copiez / collez les emails de votre cible",
  create: "Créer",
  Targets: "Cibles",
  stats: "Statistiques",
  Name: "Nom",
  Country: "Pays",
  "SMS Count": "Nombre d'SMS",
  "not available": "Indisponible",
  "IN PROGRESS": "En cours",
  SUCCESS: "Livré",
  READY: "EN ATTENTE",
  FAILED: "Echoué",
  BUFFERED: "Tamponné",
  approval: "Approbation",
  "SMS approximate cost": "Coût approximatif de la campagne",
  "SMS cost": "Coût de la campagne",
  edit: "editer",
  resume: "continuer",
  "stop definitely": "arrêter l'envoi",
  report: "rapport",
  show: "Afficher",
  block: "bloquer",
  manage: "Gérer",
  "sms bill detail": "Détail facture SMS",
  invalid_object_warning:
    "Ajouter <u>un objet</u> qui décrit clairement le contenu de votre email.",
  content_warning: "Ajouter <u>un contenu</u>",
  from_name_warning:
    "Choisissez <u>un nom</u>, par exemple celui de votre entreprise, qui aide vos abonnés à vous reconnaître.",
  invalid_from_email_warning:
    "Choisissez <u>un email valide</u> que voient vos destinataires lorsqu'ils reçoivent votre campagne.",
  recipient_count_warning:
    "Vous devez avoir au moins 1 <u>destinataire</u> pour envoyer une campagne",
  approval_required_warning:
    "Votre campagne nécessite <u>une approbation</u>, il ne sera pas possible de l'envoyer tant que vos <u>approbateurs</u> ne l'ont pas accepté",
  scheduled_approval_required_warning:
    "votre campagne peut être programmée, mais elle ne sera envoyée à la date saisie que si votre <u>approbateurs</u> l’ont accepté",
  pending: "En attente",
  cant_submit_approval:
    "Cette campagne est déjà envoyée ou supprimée, vous ne pouvez pas soumettre votre approbation ",
  "Thank you for your collaboration": "Merci pour votre collaboration",
  "see more": "voir plus",
  "see less": "voir moins",
  "approvers's feedback": "Les retours des approbateurs",
  "invalid phone number": "numéro de téléphone ou format invalide",
  smsManualTargets:
    "Entrez les numéros de téléphone mobile de votre cible au format international",
  invalidPhoneNumber: "numéro de téléphone invalide ou format invalide",
  "invalid campaign name": "nom de campagne incorrect",
  empty_e_box: "Votre boîte de réception est vide pour le moment",
  sender: "expéditeur",
  "Drag and drop a screenshot here, or click to download.":
    "Faites glisser et déposez une capture d'écran ici, ou cliquez pour télécharger.",
  "You rejected the campaign successfully, please add the reason for this rejection":
    "Vous avez rejeté la campagne avec succès, veuillez ajouter la raison de ce rejet",
  "Sort by": "Trier par",
  "oldest first": "le plus ancien",
  "newest first": "le plus récent",
  FIDUCIARIES_CLIENT: "CLIENTS OF FIDUCIARY",
  from: "de",
  searchByMail: "Recherche par e-mail",
  "view profile": "Voir le profil",
  "authorization mode": "le mode d'autorisation",
  "page url": "url de la page",
  LEGAL: "Légal",
  OPT_IN_SOFT: "OPT IN SOFT",
  OPT_IN: "OPT IN",
  OPT_OLD: "OPT OLD",
  "send GDPR campaign": "envoyer une campagne RPGD",
  ebox_empty: "Oups, E-box vide pour le moment",
  "Search for recipient": "Rechercher un destinataire",
  change: "modifier",
  "You haven't set a default image yet, choose one":
    "Vous n'avez pas encore défini d'image par défaut, choisissez-en une",
  "an image of  600 pixels in width is better displayed":
    "une image de 600 pixels de largeur est mieux affichée",
  "content manager": "gestionnaire de contenu",
  "By modifying the default text, you assume that it remains in agreement with our definition of this authorization mode":
    "En modifiant le texte par défaut, vous supposez qu'il reste en accord avec notre définition de ce mode d'autorisation",
  confirmResendEmail: "Êtes-vous sûr de vouloir réenvoyer cet email?",
  to: "à",
  toMyEBox: "revenir à mon ebox",
  of: "sur",
  showing: "Affichage de l'e-mail",
  emails: "emails",
  no_attachments: "Aucune pièce jointe à afficher pour le moment!",
  Gallery: "Galerie",
  "List of attachments received": "Liste des pièces jointes reçues",
  fields: "champs",
  "add new model": "Ajouter un nouveau modèle",
  "update the model": "mettre à jour le modèle",
  models: "modèles",
  "The subject is required!": "Le sujet est obligatoire!",
  title: "Titre",
  "apply a model": "appliquer un modèle",
  "Duplication options": "Les options de duplication",
  content: "le contenu",
  template: "le modèle",
  duplicate: "dupliquer",
  "recipients and filters": "destinataires et filtres",
  "sms models": "modèles sms",
  smsModelsPreviewMessage:
    "Mode manuel : Les champs dynamiques ne sont pas pris en considération.",
  web_version: "Version web",
  social_orientation_row: "Ligne",
  social_orientation_column: "Colonne",
  social_theme_type_logo: "Logo",
  social_theme_type_square: "Carré",
  social_theme_type_rounded: "Arrondi",
  social_theme_type_circle: "Cercle",
  social_theme_color_colored: "Coloré",
  social_theme_color_colored_bordered: "Coloré avec bordure",
  social_theme_color_black: "Noir",
  social_theme_color_black_bordered: "noir avec bordure",
  social_theme_color_white: "blanc",
  social_theme_color_white_bordered: "blanc avec bordure",
  "Search by name": "Recherche par nom",
  Customize: "Personnaliser",
  "Save template": "Enregistrer le modèle",
  "create the campaign": "créer la campagne",
  "save as template": "enregistrer le modèle",
  test: "tester",
  Static: "Statique",
  Dynamic: "Dynamique",
  "Rich Text": "Texte enrichi",
  "From URL": "Depuis une URL",
  "Create your custom template email with simple drag&drop.":
    "Sélectionnez un modèle d'email et importez automatiquement du contenue disponible",
  "Use the rich text editor to create simple emails.":
    "Créez ou sélectionnez un modèle d'email texte enrichi",
  "Create a campaign by importing the HTML code directly from a hosted URL.":
    "Sélectionnez un modèle d'email et personnalisez le contenue automatiquement",
  "Easily create your own custom newsletter.":
    "Créez ou sélectionnez un modèle d'email et personnalisez le contenue manuellement",
  "my templates": "mes modèles",
  empty_recipients_list: "la liste des destinataires est vide",
  "To get started, set a URL to import.":
    "Pour commencer, définissez une URL à importer.",
  "Drag and drop a screenshot here, or click to download.":
    "Faites glisser et déposez une capture d'écran ici, ou cliquez pour télécharger.",
  "You have already approved this version of campaign test on":
    "Vous avez déjà approuvé cette version du test de campagne le",
  "You have already rejected this version of campaign test on":
    "Vous avez déjà rejeté cette version du test de campagne le",
  "you still want to resubmit?": "vous voulez toujours soumettre à nouveau?",
  "main GDPR alert": "alerte RGPD principale",
  "image gallery": "Galerie d'images",
  scheduledFor: "Planifiée pour",
  header: "entête",
  footer: "bas de page",
  "campaign approval": "l'approbation des campagnes email",
  "Manage email models and newsletters that are ready to be sent .":
    "Gérer des modèles d'emails et des newsletters prêts à être envoyés.",
  "Create, manage and send your email campaigns .":
    "Créez, gérez et envoyez vos campagnes emails .",
  "Create, manage and send your sms campaigns .":
    "Créez, gérez et envoyez vos campagnes sms .",
  "Set the approval workflow settings for your campaigns .":
    "Définissez les paramètres du workflow d'approbation pour vos campagnes .",
  "view your invoices and your payment history .":
    "consultez vos factures et votre historique de paiement .",
  HALT: "arrêtée",
  "page size": "taille de la page",
  share_template_info:
    "Les modèles partagés sont visibles et peuvent être utilisés par l'ensemble des communautés, tandis que les modèles privés ne sont accessibles que par la communauté propriétaire.",
  shared: "partagé",
  private: "privé",
  "editor theme": "thème de l'éditeur",
  "tool alignment": "alignement de l'outil",
  "start date": "date de début",
  "end date": "date de fin",
  "sending date": "date d'envoi",
  "date range": "période",
  no_images: "Aucune image à afficher pour le moment!",
  "Images used": "Images utilisées",
  "Images available": "Images disponibles",
  scope: "portée",
  DELIVERED: "LIVRÉ",
  "NOT DELIVERED": "NON LIVRÉ",
  OPENED: "OUVERT",
  tags: "Mots clés",
  "simple email": "email simple",
  newsletter: "newsletter",
  "Oops, no layouts were found.": "Oups, aucune disposition n'a été trouvée.",
  categories: "catégories",
  "Select a layout for your block first":
    "Sélectionnez d'abord une disposition pour votre bloc",
  layouts: "les dispositions",
  "Choose the content and layout of your blocks":
    "Choisissez le contenu et la disposition de vos blocs",
  "generate the blocks": "générer les blocs",
  generate: "générer",
  use: "utiliser",
  "load blocks": "charger des blocs",
  revival: "relance",
  "partial revival": "relance partielle",
  participations: "participations",
  "survey results": "résultats du sondage",
  invitation: "invitation",
  survey: "enquête",
  survey_invitation_detail:
    "vos membres qui seront plus tard la base de vos campagnes de relance",
  survey_revival_detail:
    "les destinataires sont ceux qui ont reçu vos campagnes d'invitation mais qui n'ont pas répondu au sondage",
  survey_partial_revival_detail:
    "les destinataires qui n'ont pas encore complété l'enquête",
  survey_participations_detail:
    "les destinataires qui ont participé à l'enquête",
  survey_results_detail: "les destinataires abonnés au résultat du sondage",
  "survey title": "titre de l'enquête",
  "survey url": "url de l'enquête",
  add: "ajouter",
  days: "jours",
  "change the template": "changer le gabarit",
  "Generic Emailing": "Emailing Générique",
  combinations: "combinaisons",
  "notify talk community channel": "notifier le channel talk de la communauté",
  "contains a newsletter ?": "contient une newsletter ?",
  "Read more": "Lire la suite",
  wrapper: "enveloppe",
  "Manage your transactional mailings": "Gérer vos emailings transactionnels",
  "Select a text model": "Veuillez sélectionner un modèle de texte",
  "text model": "modèle texte",
  textModelAdded: "Un nouveau modèle texte a été ajouté avec succès",
  subject: "sujet",
  body: "corps",
  "filled blocks": "blocs remplis",
  speakers: "orateurs",
  "duplicate the model": "dupliquer le modèle",
  "edit the model": "modifier le modèle",
  "Generic model": "Modèle générique",
  textModelUsed: "Ce modèle texte est utilisé dans une combinaison",
  deleteTextModelConfirm: "Êtes-vous sûr de vouloir supprimer ce modèle texte?",
  PARTNER_GUEST: "invité partenaire",
  "visitor type": "type de visiteur",
  undefined: "non défini",
  invited: "convié",
  "send a test": "Envoyer un test",
  congress: "congressiste",
  "staff member": "exposant",
  partner: "partenaire",
  institute: "institut",
  speaker: "orateur",
  organization: "organisation",
  guest: "invité",
  discover: "découvrir",
  favorites: "favoris",
  featured: "en vedette",
  "List of NewsLetters received .": "Liste de NewsLetter reçus .",
  target: "cible",
  "Select application": "Sélectionnez une application",
  "Select type": "Sélectionnez un type",
  "formule details": "Détails formule",
  "program link": "lien programme",
  "Apply the model": "Appliquer le modèle",
  Applied: "Appliqué",
  Required: "Obligatoire",
  Please: "Veuillez",
  apply: "appliquer",
  "a text model": "un modèle de texte",
  step: "étape",
  updateSuccess: "Modification réussie",
  duplicateSuccess: "Duplication réussie",
  textModelDuplicated: "Le modèle de texte a été dupliqué avec succès",
  textModelUpdated: "Le modèle de texte a été modifié avec succès",
  invalidTitle:
    "Le titre doit comporter au moins 3 caractères et au plus 200 caractères.",
  bill: "facture",
  campaign_duplicated_successfully: "la campagne a été dupliquée avec succès",
  "user privileges": "privilèges d'utilisateur",
  "Set the privileges of your users .":
    "Définissez les privilèges de vos utilisateurs .",
  ALL: "TOUS",
  user: "utilisateur",
  "Emailing roles": "Rôles emailing",
  "Organization roles": "Rôles organization",
  "Legal Representative": "Représentant Légal",
  Official: "Officiel",
  External: "Externe",
  USER: "UTILISATEUR",
  NONE: "AUCUN",
  Preferences: "préférences",
  "emailing privileges": "privilèges emailing",
  "SMS privileges": "privilèges SMS",
  " and ": " et ",
  "Create email campaigns": "Créer campagnes email",
  "Create sms campaigns": "Créer campagnes SMS",
  "View your emails.": "Voir vos emails.",
  "Create and manage your transactionnal emails .":
    "Créer et gérez vos emails transactionnels .",
  "Create combination": "Créer combinaison ",
  "next step": "étape suivante ",
  "Send campaigns's approvals requests to teams":
    "Envoyer des demandes d'approbation de campagnes aux équipes",
  "Notifications of updates": "Notifications de mises à jour",
  "TAMTAM applications": "applications TAMTAM",
  Event: "Événement",
  Survey: "Enquête",
  Directory: "Annuaire",
  continue: "continuer",
  editor: "éditeur",
  invalid_campaign_name_warning:
    "Ajouter <u>un nom de campagne</u> qui décrit clairement le contenu de votre email.",
  sms_message_warning: "Ajouter <u>un message SMS</u>",
  not_enough_credit_warning:
    "Il ne sera pas possible d'envoyer votre campagne SMS avant d'avoir acheté du crédit.",
  "Phone numbers with check marks<br>have been verified by our system":
    "Les numéros de téléphone cochés <br> ont été vérifiés par notre système",
  "validate users sms credit requests.":
    "valider les demandes de crédit des utilisateurs sms.",
  "Your campaign will be sent in": "Votre campagne sera envoyée dans",
  "Add this email to the user and marque it as main.":
    "Ajoutez cet e-mail à l'utilisateur et le marquer comme principal.",
  "The email has been successfully resend, but it can not be added to the user :":
    "l'email a été renvoyé avec succès, mais il ne peut pas être ajouté à l'utilisateur: ",
  "that email is already assigned to another account.":
    "cet email est déjà attribué à un autre compte.",
  "events's guests": "les invités des événements",
  notificationPushTitle: "Envoyer la notification à:",
  "Create notification": "Créer une notification",
  "Notifications list": "Liste des notifications",
  "Create, manage and send mobile notifications .":
    "Créez, gérez et envoyez vos notifications mobiles .",
  createNotification: "créer une notification",
  invalid_object_notification_warning:
    "Ajouter <u>un objet</u> qui décrit clairement le contenu de votre notification.",
  recipient_count_notification_warning:
    "Vous devez avoir au moins 1 <u>destinataire</u> pour envoyer une notification",
  deleteNotificationConfirm: "Voulez-vous vraiment supprimer la notification?",
  notificationDeleted: "La notification a était supprimée avec succès",
  notification_duplicated_successfully:
    "la notification a été dupliquée avec succès",
  duplicateNotificationConfirm:
    "Voulez-vous vraiment dupliquer la notification?",
  "review not yet submitted": "avis pas encore soumis",
  "rejected at": "rejeté à",
  comment: "commentaire",
  "Type your subject": "Tapez votre sujet",
  mailbox: "boites aux lettres",
  "list of emails and SMS.": "liste des emails et SMS.",
  delivered: "Livré",
  "view other email accounts": "consulter d'autres comptes mails",
  "find other E-box": "trouver d'autre E-box",
  "E-Box not found": "E-Box introuvable",
  "no attachment found": "aucune pièce jointe trouvée",
  picture: "image",
  "attachment not found": "pièce jointe introuvable",
  "number of approvers": "nombre d'approbateurs",
  "see attachment": "voir la pièce jointe",
  params_lang_info:
    "la langue est prise en compte <br/> dans la sélection des destinataires",
  users: "utilisateurs",
  "You've moved so fast and got lost...":
    "Vous avez navigué si vite et vous êtes perdu...",
  Oops: "Oups",
  "Confirmation of deletion": "Confirmation de la suppression",
  "Confirmation of duplication": "Confirmation de la duplication",
  "Confirmation of sending": "Confirmation de l'envoi",
  "Confirmation of rejection": "Confirmation du rejet",
  "Confirmation of validation": "Confirmation de la validation",
  "Halt confirmation": "Confirmation de l'arrêt",
  ROLES_TYPES: "types de rôles",
  "send thank you": "envoyer remerciement",
  SHAREHOLDER: "ACTIONNAIRE",
  QUALITY_MANAGER: "RESPONSABLE QUALITÉ",
  "Select role status": "Sélectionnez le statut du rôle",
  ROLES_STATUS: "Statut des rôles",
  COLLABORATOR: "COLLABORATEUR",
  "Select a model to apply for each language":
    "Selectionnez un modèle à appliquer pour chaque langue",
  library: "bibliothèque",
  "Generic emails": "Emails génériques",
  UnavailableLanguagesNote: "Note: veuillez remplir les langues indisponibles",
  "The existing configuration has been loaded":
    "La configuration existante a été chargée",
  texts: "textes",
  saveTextModelConfirmation:
    "La cible a été changé! Voulez-vous vraiment enregistrer le modèle de texte?",
  "Data saved successfully": "Les données ont bien été enregistrées",
  "Add this language": "Ajouter cette langue",
  "Unavailable language": "Langue indisponible",
  "view preview": "voir l'aperçu",
  "emailing found": "emailing trouvé",
  "default settings": "paramètres par défaut",
  "looks like you are not authorized to access this page":
    "vous n'êtes pas autorisé à accéder à cette page",
  "contact your community admin":
    "contactez l'administrateur de votre communauté",
  "main email": "email principal",
  "show all": "Afficher tous",
  "hide all": "Cacher tous",
  submit: "soumettre",
  "verified senders": "expéditeurs vérifiés",
  "reset emails": "réinitialiser les mails",
  reset_generic_emails:
    "Lorsque vous réinitialiser les mails, la plateforme recharge l’email le plus pertinent (celui de votre communauté, ou à défaut, celui de la plateforme tamtam.pro) et remplace l’email actuellement actif",
  "Manage your emails": "Gérer vos emails",
  "emails types": "types d'emails",
  "Add your custom text": "Ajoutez votre texte personnalisé",
  mainTextMessage: "Définir ce texte modèle comme principal de l'organization",
  confirmDeleteEmail: "Êtes-vous sûr de vouloir supprimer cet email?",
  yesDelete: "OUI, SUPPRMIER",
  "Manage your sending emails": "Gérer vos mails d'envoi",
  complete: "compléter",
  "Customize with dynamic fields": "Personnalisez avec des champs dynamiques",
  "list of campaigns": "liste des campagnes",
  "campaign list": "campagnes email",
  Main: "Principal",
  DRAFT: "Brouillon",
  organizer: "organisateur",
  "main text": "texte principal",
  editMainTextConfirm: "Êtes-vous sûr de vouloir rendre ce texte principal?",
  UndoEditMainTextConfirm:
    "Êtes-vous sûr de vouloir ne pas utiliser ce texte comme principal?",
  mainTextModel: "Ceci est un modèle de texte principal!",
  "save the template": "enregistrer le gabarit",
  "template preview": "aperçu du gabarit",
  "templates list": "liste des gabarits",
  "load a template": "charger un gabarit",
  "select an article layout": "sélectionner une disposition d'article",
  "select a slot layout": "sélectionner une disposition de créneau",
  "select a speaker layout": "sélectionner une disposition d'orateur",
  "select a static block": "sélectionner un bloc statique",
  "Send your sms campaigns": "Envoyez vos campagnes sms",
  "All blocks are filled": "Tous les blocs sont remplis",
  newTemplate: "Modèle sans titre",
  "sending in progress": "envoi en cours",
  "new message": "nouveau message",
  selectCommunity: "choisissez votre communauté",
  listCommunity: "Liste des communautés membre",
  CommunityUnauthorised: "Cette communauté n’a pas d'accès à cette application",
  small: "petit",
  normal: "normale",
  large: "grand",
  huge: "énorme",
  partner_guest: "invité partenaire",
  coupon_model: "coupon",
  new: "nouveau",
  "Attach a file": "Joindre un fichier",
  "Drag and drop a picture here, or click to select picture to upload.":
    "Faites glisser et déposer votre image ici, ou cliquez pour sélectionner l'image à télécharger.",
  "Link title": "Titre du lien",
  "Link target": "Cible du lien",
  "Edit text": "Éditer le texte",
  reset_generic_email:
    "Lorsque vous réinitialiser cet e-mail, la plateforme recharge l’email le plus pertinent (celui de votre communauté, ou à défaut, celui de la plateforme tamtam.pro) et le remplace",
  "reset email": "réinitialiser le mail",
  Context: "Contexte",
  ATTESTATION: "Certificat",
  ORDER: "Commande",
  INVOICE: "Facture",
  TAG: "",
  ARTICLE: "",
  CLICKED: "CLIQUÉ",
  "my models": "mes modèles",
  "shared models": "modèles partagés",
  width: "largeur",
  height: "hauteur",
  gravity: "gravité",
  North: "Nord",
  South: "Sud",
  East: "Est",
  West: "Ouest",
  Smart: "Intelligent",
  Blur: "Brouiller",
  BW: "Noir & blanc",
  Sharp: "Net",
  Category: "Categorie",
  desktop: "bureau",
  mobile: "mobile",
  "write your email": "rédiger votre mail",
  "add the subject and content of your email":
    "ajouter l'objet et le contenu de votre email",
  "give a subject to your campaign": "donnez un sujet à votre campagne",
  "please fill in all the required fields":
    "veuillez remplir tous les champs obligatoires",
  proceed: "continuer",
  "Emailing editor": "Editeur Emailing",
  "Rich text editor": "Editeur de texte enrichi",
  "Choose a model": "Choisir un modèle",
  "Compose my email": "Rédiger mon email",
  "Send campaign": "Envoyer la campagne",
  "Add content": "Ajouter du contenu",
  "Confirmation of switching models": "Confirmation du changement de modèle",
  confirmSwitchTheme:
    "Vous êtes sur le point de changer de modèle. Les styles et le contenue seront réinitialisés pour correspondre au nouveau modèle! Êtes-vous sur de vouloir continuer ?",
  invalidName:
    "le titre doit comporter au moins 2 caractères et pas plus de 50 caractères",
  "Please select a template to proceed":
    "Veuillez sélectionner un modèle pour continuer",
  "Are you sure you want to save this theme category ?":
    "Êtes-vous sûr de vouloir enregistrer cette catégorie de thème",
  "mailing lists": "Listes de diffusion",
  "New list": "Nouvelle liste",
  "Mandatory mailing list": "Liste de diffusion obligatoire",
  mailingListAdded: "La liste de diffusion a été enregistrée avec succès",
  "Add new mailing list": "Ajouter une nouvelle liste de diffusion",
  "Manage mailing lists .": "Gérez les listes de diffusion .",
  mandatory: "obligatoire",
  optional: "optionnelle",
  confirmDeleteCategory:
    "Êtes-vous sûr de vouloir supprimer cette catégorie de thème ?",
  "Enter the name and description of your mailing list":
    "Tapez le nom et la description de votre liste de diffusion",
  "languages available": "langues disponibles",
  "You must enter at least one name !": "Vous devez saisir au moins un nom !",
  "There is no description for this mailing list !":
    "Il n'y a pas de description pour cette liste de diffusion !",
  "theme categories": "catégories de thème",
  "Are you sure you want to delete this mailing list":
    "Êtes-vous sûr de vouloir supprimer cette liste de diffusion",
  "Edit mailing list": "Modifier la liste de diffusion",
  mailingListEdited: "La liste de diffusion a été modifiée avec succès",
  editSuccess: "Modification réussie",
  "Add new theme category": "Ajouter une nouvelle catégorie de thème",
  "New theme category": "Nouvelle catégorie",
  return: "retour",
  "Manage users": "Gérez les utilisateurs",
  "Manage the categories of your themes": "Gérez les catégories de vos thèmes",
  "Manage private texts": "Gérez les textes privés",
  "Edit link": "Modifier le lien",
  mask: "masquer",
  unmask: "démasquer",
  masked: "masqué",
  visibility: "visibilité",
  "3 langues available": "3 langues disponible",
  "Type the title of your category": "Tapez le titre de votre catégorie",
  "List of categories": "Liste des catégories",
  "Add, modify or delete your categories":
    "Ajoutez, modifiez ou supprimez vos catégories",
  "save campaign": "enregistrer la campagne",
  "save template": "enregistrer le gabarit",
  "You have": "Vous avez",
  "empty link(s) in your model": "lien(s) vide(s) dans votre modèle",
  received: "reçus",
  "Adapt my email": "Adapter mon email",
  event_cycle: "cycle d'événements",
  "APPROVAL REQUEST": "DEMANDE D'APPROBATION",
  "APPROVAL REJECTED": "APPROBATION REJETÉE",
  order: "commande",
  invoice: "facture",
  credit_note: "note de crédit",
  PAYMENT_REMINDER: "RAPPEL DE PAIEMENT",
  reminder: "rappel",
  "reminder type": "type du rappel",
  folder: "dossier",
  individual: "individuel",
  "client folder": "dossier client",
  "Select client folder": "Sélectionnez le dossier client",
  "Select role": "Sélectionnez le rôle",
  FOLDER: "DOSSIER",
  "my folders": "mes dossiers",
  folders: "dossiers",
  "Select folder": "Sélectionner un dossier",
  Community: "communauté",
  "view your campaigns stats": "consulter les statistiques de vos campagnes",
  "The evolution of the number of clicks and openings":
    "L'évolution de nombre de click et d'ouverture",
  "the number of campaigns sent": "Le nombre de campagnes envoyées",
  "number of unsubscriptions": "nombre des désinscrits",
  "number of bounces": "nombre des bounces",
  "number of complaints": "nombre de complaintes",
  "The Evolution in campaign opening percentages":
    "L'évolution des pourcentages d'ouverture de campagne",
  month: new Array(
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ),
  "Most popular URL": "L’URL le plus populaires ",
  Clicks: "clics",
  "Reach graph per month": "Graphique du reach par mois",
  "Evolution graph of undelivered emails":
    "Graphique d'évolution du nombre d'emails non livré",
  "Engagement graph: number of clicks per campaign":
    "Graphique d’engagement: nombre de clics par campagne",
  "reload the page": "recharger la page",
  "links inventory": "inventaire des liens",
  "no link found": "aucun lien trouvé",
  "Evolution graph of the community's average scores":
    "Graphique d'évolution des scores moyens de la communauté",
  "commitment score": "score d'engagement",
  Members: "Membres",
  DEFAULT_MODE: "Par défaut",
  Default: "Par défaut",
  oeccbbMembers: "Membres oeccbb",
  FEMALE: "Femmes",
  MALE: "Hommes",
  monthly: "mensuel",
  annual: "annuel",
  fr: "Francais",
  en: "Anglais",
  nl: "Néérlandais",
  link: "lien",
  "free section": "rubrique libre",
  "Add a free section": "Ajouter une rebrique libre",
  example: "exemple",
  selectType: "Veuillez selectionnez un type",
  TEXT: "TEXTE",
  future: "Future",
  past: "Passé",
  NUMBER: "NOMBRE",
  "LONG DATE": "DATE FORMAT LONG",
  "SHORT DATE": "DATE FORMAT COURT",
  "Your campaign is now based on the text model":
    "Votre campagne est maintenant basée sur le modèle de texte",
  "Text model changed successfully": "Modèle de texte modifié avec succès",
  "Text color": "Couleur du texte",
  "Background color": "Couleur de l'arrière plan",
  "Border color": "Couleur de la bordure",
  "My community": "Ma communauté",
  "Community engagement": "Engagement de la communauté",
  "Emailing in sending": "Emailing dans sending",
  "Performance of my emailings": "Performance de mes emailings",
  "Number of clicks": "Nombre de clics",
  "Number of subscribers / unsubscribers": "Nombre d'abonées/désabonées",
  "Number of hours": "Nombre d’heures",
  "Openings data": "Données d’ouvertures",
  "Incidents data": "Données d’incidents",
  "Bounces data": "Données de rebonds",
  "Percentage of complaints": "Pourcentage de plaintes",
  "Number of mailings per language": "Nombre d’emailing par langue",
  "Evolution of the number of clicks": "Evolution du nombre de clics",
  "Evolution of the number of new subscribers / unsubscribers":
    "Evolution du nombre de nouveaux abonées/désabonées",
  "Evolution of the number of openings": "Evolution du nombre d’ouverts",
  "Evolution of active people": "Evolution des personnes active",
  "Evolution of the average score over time of the community":
    "Evolution du score moyen dans le temps de la communauté",
  "Average score": "Score moyen",
  "Very active": "Très active",
  "Moderately active": "Moyennement active",
  Inactive: "Non active",
  "Number of persons": "Nombre de personnes",
  "Not very active": "Peu active",
  "Percentage of openings": "Pourcentage d'ouvertures",
  "Number of unsubscribers": "Nombre de désabonées",
  "Number of subscribers": "Nombre d'abonées",
  "Permanent bounces": "Rebonds permanents",
  "Old permanent bounces": "Ancien rebonds permanents",
  "Transient bounces": "Rebonds transitoires",
  "Undetermined bounces": "Rebonds indéterminés",
  Today: "Aujourd'hui",
  days: "jours",
  hours: "heures",
  seconds: "secondes",
  year: "Année",
  source: "Source",
  paid: "Payé",
  "All the time": "Tout le temps",
  "Last Year": "L'année dernière",
  "Last Week": "la semaine dernière",
  "Last Month": "le mois dernier",
  "Number of emails by type": "Nombre d'emailings par type",
  "Best clicked links": "Meilleurs liens cliqués",
  loading: "chargement",
  "event revival": "relance d'événement",
  EVENT_REVIVAL: "relance d'événement",
  "event reminder": "rappel d'événement",
  EVENT_REMINDER: "rappel d'événement",
  CURRENCY: "DEVISE",
  "Enter the text": "Saisir le texte",
  "Select a date": "Sélectionnez une date",
  "Enter a time": "Saisir une heure",
  "Selected Filters": "Filtres sélectionnés",
  "Reset filter": "Réinitialiser le filtre",
  "CONFIGURE YOUR COMMUNITY'S STATISTICS":
    "CONFIGUREZ LES STATISTIQUES DE VOTRE COMMUNAUTÉ",
  "Dashboard Page": "Tableau de bord",
  "Activate / Deactivate statistics calculation":
    "Activer / Désactiver le calcul des statistiques",
  enterFreeSection: "Configurez vos rubriques libres pour poursuivre!",
  "Select an option": "Sélectionner une option",
  "Add option": "Ajouter une option",
  "Delete option": "Supprimer une option",
  "Please enter options": "Veuillez entrer des options",
  LIST: "LISTE",
  CYCLE_GUEST: " INVITÉ CYCLE ",
  "scheduled sending": "Envoi planifié",
  "change sending date": "modifier la date d'envoi",
  "Change delivery date": "Modifier la date d'envoi",
  scheduled_date_expired: "non approuvée | Date de planification expirée",
  "Save text model": "Enregistrer le modèle",
  "Answer on Tamtam": "Répondre sur Tamtam",
  optionalName: "optionnel",
  "create new": "créer un nouveau",
  "save as": "enregistrer sous",
  "Create a chat conversation": "Créer une conversation sur le chat",
  yesContinue: "OUI, CONTINUER",
  "No, fill in the links": "Non, Remplir les liens",
  confirmContinue: "Êtes-vous sûr de vouloir continuer?",
  united_associates: "united associates",
  "Replace with": "Remplacer par",
  Fix: "Fixer",
  "Invalid emails": "Emails défaillants",
  "This email address is no longer valid":
    "Cette adresse email n'est plus valable",
  "Stop sharing": "Ne plus partager",
  stop_share_template_info:
    "Ce modèle ne va plus être visible par l'ensemble des communautés, et ne peut être accessible que par la communauté propriétaire.",
  Share_newsletter_campaign: "Partager la campaign newsletter : ",
  waiting_for_campaign_to_be_sent_to: "en attente d'envoi de la campagne ",
  Anti_spam_test_01: "Test anti spam 01",
  Broadcast_link: "Lien de diffusion",
  To_copy: "Copier",
  Add_your_broadcast_contacts: "Ajoutez un contact pour le partage",
  This_campaign_is_shared_with: "Cette campagne est partagée avec ",
  Contacts: "contact(s)",
  clicks_count: "Nombre de clics",
  Emails_opened: "Emails ouverts",
  Send: "Envoyer",
  Description_add_your_broadcast_contacts:
    "Vous ajoutez ici l'adresse email d'un contact pour lequel vous souhaitez l'envoi de cet email",
  Description_waiting_for_campaign_to_be_sent_to:
    "Cette campagne est envoyée en toute sécurité avec les personnes reprises ci-dessous sans usage de votre auto-login",
  steps_paragraph:
    "Pour envoyer votre campagne de newsletter, vous devez suivre les étapes suivantes",
  sending_steps: "Étapes d’envoi",
  newsletter_config_title: "Configurez les informations de votre campagne",
  resendTest: "R’Envoyer le test",
  ignore: "ignorer",
  aproveAndSave: "Approuver et sauvegarder",
  See_more: "Voir plus",
  See_less: "Voir moins",
  schedule_sending_campaign: "Planifiez l'envoi de votre campagne",
  result_number: "Nombre de résultats",
  manualRecipientNullWarning:
    "Ajouter un email manuellement ou désélectionner le type manuel.",
  max_recipients_alert:
    "Vous avez excédé la limite de destinataires autorisée pour votre plan",
  max_recipients_alert_desc:
    "Pensez à ajuster votre plan pour permettre un nombre plus élevé de destinataires.",
  selectAll: "Sélectionner tous",
  info_selection: "Les 20 destinataires de cette page sont sélectionnées.",
  desc_selection: "destinataires dans",
  desc_selection_1: "Sélectionner que les ",
  desc_selection_2: " destinataires de cette page.",
  the: "Les",
  its_selected: "sont sélectionnées.",
  clear_selection: "Effacer la selection.",
  deselect: "Déselectionner",
  personalized: "Personnalisée",
  lite: "Lite",
  edito: "Édito",
  basic: "Basic",
  choose_recipients_warning:
    "Veuillez sélectionner au moins un destinataire pour le type",
  number_of_newsletter: "Newsletter N°",
  introduction: "Introduction",
  invalid_introduction:
    "L'introduction doit comporter au moins 5 caractères et pas plus de 200 caractères",
  placeholder_intro: "Donnez à votre campagne un texte introductif",
  duplicateNewsletterTitle: "Dupliquer la newsletter",
  duplicateNewsletter: "Souhaitez-vous dupliquer la newsletter ?",
  list_campaigns: "Liste campagnes",
  program_newsletters: "programmer des newsletters",
  number_of_campaigns: "nombre de campagnes",
  periods: "périodes",
  approvedAt: "approuvée à",
  acceptedApproval: "Approbation accepté par",
  sendTo: "Envoyer à",
  insertYourTargetsEmails: "Insérez les courriels de votre cible",
  approval_threshold: "Seuil d'approbation",
  principal: "Principale",
  approved: "approuvée",
  rejected: "rejetée",
  sent: "envoyée",
  approvalAccepted: "Approbation acceptée",
  approvalRejected: "Approbation rejetée",
  pendingApproval: "En attente d’approbation",
  Reduce: "Réduire",
  readMore: "Lire plus",
  resendApproval: "Renvoyer l'approbation",
  total: "Total",
  cupboard: "Casier / Armoire",
  profession: "Profession",
  auto_send_approved_newsletter_message:
    "Envoyer la campagne une fois que le seuil d'approbation a été atteint",
  valid_emails: "Emails valides",
  duplicate_users_alert:
    "Nombre total de destinataires uniques pour les types sélectionnés (les doublons ont été supprimés).",
  send_approval: "envoyer l'approbation",
  new_mail_address: "Nouvelle adresse mail",
  mail_address: "adresse mail...",
  mail_address_invalid: "Veuillez saisir une adresse mail valide",
  confirm_unsubscribe_msg:
    "Veuillez confirmer que vous souhaitez vous désabonner de votre adresse e-mail",
  from_future_mails_from: "des futurs e-mails de",
  unsubscribed_msg: "Vous avez été désinscrit !",
  warning_unsubscribe_msg: "Vous ne recevrez plus d'e-mails de",
  unsubscribed_by_accident: ", désabonné par accident ?",
  subscribed_msg: "s'abonner",
  unsubscribe_from_ua: "Se désabonner de United Associate",
  subject_message_info_part_1: "Veuillez retirer ",
  subject_message_info_part_2: " caractères du texte.",
  steps_paragraph_ritch_text:
    "Pour envoyer votre campagne de text enrichi, vous devez suivre les étapes suivantes",
  duplicateRitchTextTitle: "Dupliquer le text enrichi",
  duplicateRitchText: "Souhaitez-vous dupliquer le text enrichi ?",
  list_campaigns_subtitle: "Visualiser et gérer vos campagnes de newsletters",
  uploadImage: "Ajouter Image",
  imageUpload: "Image téléchargée et enregistrée avec succès",
  imageUploadSuccess : "Téléchargement réussi",
  imageNoUpload: "Erreur lors du téléchargement de l'image",
  imageUploadError: "Échec du téléchargement",
  secondSignatureEmail : "Email deuxieme signature",
  enterProfession :"Entrez votre profession",
  giveCampaignTitle: "Donnez un titre à votre campagne",
};
